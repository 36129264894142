import {
  Box,
  Heading,
  Text,
  Stack,
  Container,
  Grid,
  GridItem,
  Flex,
  useColorModeValue as mode,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';

export default function Home() {
  return (
    <>
      <Box position={'relative'}>
        <Container maxW={'8xl'} zIndex={10} position={'relative'}>
          <Stack
            flex={1}
            color={'mkyfive.50'}
            justify={{ lg: 'center' }}
            py={{ base: 4, md: 10, xl: '1em' }}
          >
            {/* <Box mb={{ base: 8, md: 8 }}>
                <Container
                    py={3}
                    maxW={'8xl'}
                    zIndex={10}
                    position={'relative'}
                    bg={'mkytwo.500'}
                    borderRadius={'xl'}
                    boxShadow="dark-lg"
                    alignContent={'center'}
                > */}
                
                  <Grid
                    h='500px'
                    gap={2}
                    templateColumns={{ sm: "repeat(1, 1fr)", md: "repeat(2, 1fr)", lg: "repeat(2, 1fr)" }}
                    templateRows={6}
                  >
                    <GridItem 
                      colSpan={{ sm: 1, md: 1, lg: 1 }}
                      rowSpan={3} 
                      bg={'mkyfive.50'} 
                      borderRadius={'xl'} 
                      color={'mkyone.900'}
                      boxShadow='0 0 35px rgba(112, 172, 70, 0.8)' // Set the drop shadow color
                      // boxShadow='dark-lg'
                    >
                        Carosel From Webiny
                    </GridItem>
                    <GridItem 
                      colSpan={{ sm: 1, md: 1, lg: 1 }} 
                      rowSpan={6} 
                      bg={'mkyfive.50'} 
                      borderRadius={'xl'} 
                      color={'mkyone.900'}
                    >
                      Marketocracy Proprietary Market Sentiment Image from Webiny 
                    </GridItem>
                    <GridItem 
                      colSpan={{ sm: 1, md: 1, lg: 1 }}
                      rowSpan={3} 
                      bg={'#70AC46'} 
                      borderRadius={'xl'} 
                      color={'mkyone.900'}boxShadow='0 0 35px rgba(112, 172, 70, 0.8)'
                      border='2px' borderColor='mkyone.600'
                    >
                      News Letter ~ connect to Mail Chimp
                    </GridItem>
                  </Grid>
                  <Grid
                    h='800px'
                    gap={2}
                    templateColumns={{ sm: "repeat(1, 1fr)", md: "repeat(2, 1fr)", lg: "repeat(3, 1fr)" }}
                    templateRows={12}
                  >
                    <GridItem 
                      colSpan={{ sm: 1, md: 1, lg: 1 }}
                      rowSpan={12} 
                      bg={'mkyfive.50'} 
                      borderRadius={'xl'} 
                      color={'mkyone.900'}
                    >
                      Facebook Feed
                    </GridItem>
                    <GridItem 
                      colSpan={{ sm: 1, md: 1, lg: 1 }}
                      rowSpan={12} 
                      bg={'#FBD04A'} 
                      borderRadius={'xl'} 
                      color={'mkyone.900'}
                    >
                      Pod casts
                    </GridItem>
                    <GridItem 
                      colSpan={{ sm: 1, md: 1, lg: 1 }} 
                      rowSpan={3} 
                    >
                      <Grid
                        h='200px'
                        templateColumns={{ sm: "repeat(3, 1fr)", md: "repeat(3, 1fr)", lg: "repeat(3, 1fr)" }}
                        templateRows="1fr"
                        gap={2}
                      >
                        <GridItem 
                          colSpan={{ sm: 1, md: 1, lg: 1 }}
                          rowSpan={12} 
                          bg={'mkyfive.50'} 
                          borderRadius={'xl'} 
                          color={'mkyone.900'}
                        >
                          S&P 500
                        </GridItem>
                        <GridItem 
                          colSpan={{ sm: 1, md: 1, lg: 1 }}
                          rowSpan={12} 
                          bg={'mkyfive.50'} 
                          borderRadius={'xl'} 
                          color={'mkyone.900'}
                        >
                          Dow Jones Industrial
                        </GridItem>
                        <GridItem 
                          colSpan={{ sm: 1, md: 1, lg: 1 }}
                          rowSpan={12} 
                         bg={'mkyfive.50'} 
                          borderRadius={'xl'} 
                          color={'mkyone.900'}
                        >
                          NASDAQ Composite
                        </GridItem>
                      </Grid>
                    </GridItem>
                    <GridItem 
                      colSpan={{ sm: 1, md: 1, lg: 1 }} 
                      rowSpan={3} 
                      bg={'mkyfive.50'} 
                      borderRadius={'xl'} 
                      color={'mkyone.900'}
                    >
                      Treasury Yields
                    </GridItem>
                    <GridItem 
                      colSpan={{ sm: 1, md: 1, lg: 1 }} 
                      rowSpan={3} 
                      bg={'mkyfive.50'} 
                      borderRadius={'xl'} 
                      color={'mkyone.900'}
                    >
                      Inflation Indicator
                    </GridItem>
                    <GridItem 
                      colSpan={{ sm: 1, md: 1, lg: 1 }} 
                      rowSpan={3} 
                      bg={'mkyfive.50'} 
                      borderRadius={'xl'} 
                      color={'mkyone.900'}
                    >
                      Financial Calendar
                    </GridItem>
                  </Grid>
              </Stack>
          </Container>
        </Box>
    </>
  );
}

