import { React } from 'react';
import {
    Box,
    Flex,
    Container,
    Stack,
    Heading,
    Text,
    Link
} from '@chakra-ui/react';
import { ExternalLinkIcon } from '@chakra-ui/icons'

export default function Portfolio() {
    // let navigate = useRedirect();
    return (
        <>
            <Box position={'relative'}>
                <Container maxW={'8xl'} zIndex={10} position={'relative'}>
                    <Stack
                        flex={1}
                        color={'mkyfive.50'}
                        justify={{ lg: 'center' }}
                        py={{ base: 4, md: 10, xl: '1em' }}
                    >
                        <Box mb={{ base: 8, md: 8 }}>
                            <Container
                                py={3}
                                maxW={'8xl'}
                                zIndex={10}
                                position={'relative'}
                                bg={'mkytwo.500'}
                                borderRadius={'xl'}
                                boxShadow="dark-lg"
                            >
                                {/* <Text
                                    fontSize={'lg'}
                                    color={'mkyfive.50'}
                                    textAlign={'left'}
                                    px={'.75em'}
                                >
                                   
                                </Text> */}
                                <Heading
                                color={'mkytwo.500'}
                                mb={2}
                                fontSize={{ base: '3xl', md: '3xl' }}
                                textAlign={'left'}
                            >
                                Contest to begin June 1st
                            </Heading>
                            </Container>
                        </Box>
                    </Stack>
                </Container>
            </Box>
        </>
    );
}
