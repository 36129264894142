// import {
//   Box,
//   Divider,
//   Flex,
//   Stack,
//   Text,
//   Link,
//   useColorModeValue as mode,
// } from '@chakra-ui/react'
// import * as React from 'react'
// import Logo from '../logo/logo'
// import { PurpleButton } from '../buttons/purple'
// import { BlueButton } from '../buttons/blue'
// import { BeatingHeart } from 'beating-heart-emoji'
// import 'beating-heart-emoji/dist/index.css'

// const packageJson = require('../../../package.json');

// export default function Footer() {
//   return(
//     <Box 
//       bg={mode('gray.200', 'gray.800')} 
//       pt="2" 
//       pb="5" 
//       as="footer" 
//       w="100%"
//     >
//       <Box 
//         maxW={{ 
//           base: 'xl', 
//           md: '7xl' 
//         }} 
//         mx="auto" 
//         px={{ 
//           base: '6', 
//           md: '8'
//          }}
//       >
//         <Stack
//           spacing="8"
//           direction={{
//             base: 'column',
//             md: 'row',
//           }}
//           justify="space-between"
//           py={{
//             base: '12',
//             md: '16',
//           }}
//         >
//           <Stack
//             spacing={{
//               base: '6',
//               md: '8',
//             }}
//             align="start"
//           >
//             <Logo />
//             <Text 
//               color="muted" 
//               align="left"
//             >
//               Please make checks payable to The Downriver Foster Closet.
//             </Text>
//             <Text 
//               align="left"
//             >
//               Mail To:<br />
//               Downriver Foster Closet <br />
//               1753 Ford Ave. <br />
//               Wyandotte, MI 48192 <br /> 
//             </Text>
//           </Stack>
//           <Stack
//             direction={{
//               base: 'column-reverse',
//               md: 'column',
//               lg: 'row',
//             }}
//             spacing={{
//               base: '12',
//               md: '8',
//             }}
//           >
//             <Stack direction="row" spacing="8">
//               <Stack spacing="4" minW="36" flex="1">
//                 <Text fontSize="lg" fontWeight="semibold" color="subtle">
//                   Donate
//                 </Text>
//                 <Link 
//                   href="https://www.facebook.com/Downriverfostercloset" 
//                   target="_blank" 
//                   rel="noopener noreferrer"
//                   style={{ textDecoration: 'none' }} 
//                   isExternal
//                 >
//                   <BlueButton>
//                     Facebook
//                   </BlueButton>
//                 </Link>
//                 </Stack>
//                 <Stack spacing="4" minW="36" flex="1">
//                   <Text fontSize="lg" fontWeight="semibold" color="subtle">
//                     Volunteer
//                   </Text>
//                   <Link 
//                     href='https://docs.google.com/forms/d/e/1FAIpQLSc0hhWyU6FJhJy3waVdad8TOhp0ZJceyy5Ib9zcFeMQ22I_qQ/viewform?fbclid=IwAR3oU31AbP-ii2VuPnVbGaDc1YY62AvP_UDt_bZyK7SYlOTZHsUDuGtE6sc' 
//                     isExternal
//                     target="_blank"
//                     rel="noopener noreferrer"
//                     style={{ textDecoration: 'none' }}
//                   >
//                     <PurpleButton>
//                       Volunteer
//                     </PurpleButton>
//                   </Link>
//                 </Stack>
//               </Stack>
//             </Stack>
//           </Stack>
//           </Box>
//         <Divider />
//         <Stack
//           w="100%"
//           pt="4"
//           pb="2"
//           pl="5"
//           pr="5"
//           justify="space-between"
//           direction={{
//             base: 'column-reverse',
//             md: 'row',
//           }}
//           align="center"
//         >
//           <Text fontSize="md" color="subtle">
//             &copy; {new Date().getFullYear()} 
//               Downriver Foster Closet
//               <Link
//                 href='https://dgxn14gd1tpd4.cloudfront.net/' 
//                 isExternal
//                 target="_blank"
//                 rel="noopener noreferrer"
//                 style={{ textDecoration: 'none' }}
//               >
//                 <small>
//                   {'  '}v{packageJson.version}
//                 </small>
//               </Link>
//             </Text>
//           <Flex
//             direction={'row'}
//             spacing={6}
//           >
//             <Text 
//               fontSize="xs" 
//               color="subtle"
//             >
//               Built with 
//               <BeatingHeart /> 
//               by 
//               <Link
//                 href='https://www.pingsoftwareusa.com/home' 
//                 isExternal
//                 target="_blank"
//                 rel="noopener noreferrer"
//                 style={{ textDecoration: 'none' }}
//               >
//               {' '} Ping Software USA
//               </Link>
//             </Text>
//           </Flex>
//         </Stack>
//     </Box>
// )
//     }


    import {
      Box,
      chakra,
      Container,
      Link,
      Stack,
      Text,
      useColorModeValue,
      VisuallyHidden,
  } from '@chakra-ui/react';
  import {
      FaInstagram,
      FaTwitter,
      FaYoutube,
      FaFacebook,
      FaLinkedin,
      FaTiktok
  } from 'react-icons/fa';
  import { ReactNode } from 'react';
  import Logo from '../logo/logo'
  
  const packageJson = require('../../../package.json');
  
  
  // Social Media Links
// https://twitter.com/Marketocracy2
// https://www.facebook.com/profile.php?id=100087197932735
// https://www.instagram.com/marketocracy/
// https://www.tiktok.com/@marketocracy
// https://www.linkedin.com/company/marketocracymasterscm/
// https://www.youtube.com/channel/UCX2C5qOnCazAuCrGJ0asx0g
  
  const postUrl = 'https://www.marketocracymasters.com/';
  const postTitle = 'This may be of interest to you.'
  
  
  
  const SocialButton = ({ children, label, href }) => {
      return (
          <chakra.button
              bg={useColorModeValue('blackAlpha.100', 'whiteAlpha.100')}
              rounded={'full'}
              w={8}
              h={8}
              cursor={'pointer'}
              as={'a'}
              href={href}
              display={'inline-flex'}
              alignItems={'center'}
              justifyContent={'center'}
              transition={'background 0.3s ease'}
              _hover={{
                  bg: useColorModeValue('blackAlpha.200', 'whiteAlpha.200'),
              }}
          >
              <VisuallyHidden>{label}</VisuallyHidden>
              {children}
          </chakra.button>
      );
  };
  
  export default function SmallCentered() {
      return (
          <Box
              bg={useColorModeValue('gray.50', 'gray.900')}
              color={useColorModeValue('gray.700', 'gray.200')}
              marginTop={10}
          >
              <Container
                  as={Stack}
                  maxW={'6xl'}
                  py={2}
                  spacing={4}
                  justify={'center'}
                  align={'center'}
              >
                  <Stack direction={'row'} spacing={6}>
                      <Link href={'/'}>Home</Link>
                      <Link href={'/contact'}>Contact</Link>
                      <Link
                          href={
                              '#'
                          }
                          target="_blank"
                      >
                          Terms
                      </Link>
                      <Link
                          href={
                              '#'
                          }
                          target="_blank"
                      >
                          Privacy
                      </Link>
                  </Stack>
              </Container>
  
              <Box
                  borderTopWidth={1}
                  borderStyle={'solid'}
                  borderColor={useColorModeValue('gray.200', 'gray.700')}
              >
                  <Container
                      as={Stack}
                      maxW={'6xl'}
                      py={4}
                      direction={{ base: 'column', md: 'row' }}
                      spacing={4}
                      justify={{ base: 'center', md: 'space-between' }}
                      align={{ base: 'center', md: 'center' }}
                  >
                      <Text>
                          ©{new Date().getFullYear()}
                          {'  '} marketocracy.io All rights reserved
                          <small>
                              {'  '}v{packageJson.version}
                          </small>
                      </Text>
                      <Stack direction={'row'} spacing={6}>
                          <SocialButton
                              label={'Twitter'}
                              href={' https://twitter.com/Marketocracy2'}
                              target="_blank"
                              rel="noopener noreferrer"
                          >
                              <FaTwitter color="#1da1f2"/>
                          </SocialButton>
                          <SocialButton
                              label={'Facebook'}
                              colorScheme={'facebook'}
                              href={'https://www.facebook.com/profile.php?id=100087197932735'}
                              target="_blank"
                              rel="noopener noreferrer"
                          >
                              <FaFacebook color="#1877f2"/>
                          </SocialButton>
                          <SocialButton
                              label={'YouTube'}
                              href={'https://www.youtube.com/channel/UCX2C5qOnCazAuCrGJ0asx0g'}
                              target="_blank"
                              rel="noopener noreferrer"
                          >
                              <FaYoutube color="#c4302b"/>
                          </SocialButton>
                          <SocialButton
                              label={'Instagram'}
                              href={'https://www.instagram.com/marketocracy/'}
                              target="_blank"
                              rel="noopener noreferrer"
                          >
                              <FaInstagram color="#bc2a8d"/>
                          </SocialButton>
                          <SocialButton
                              label={'Linkedin'}
                              href={
                                  'https://www.linkedin.com/company/marketocracymasterscm/'
                              }
                              target="_blank"
                              rel="noopener noreferrer"
                          >
                              <FaLinkedin color="#0077b5"/>
                          </SocialButton>
                          {/* <SocialButton
                              label={'TikTok'}
                              href={
                                  'https://www.linkedin.com/company/marketocracymasterscm/'
                              }
                              target="_blank"
                              rel="noopener noreferrer"
                          >
                              <FaTiktok color="#0077b5"/>
                          </SocialButton> */}
                      </Stack>
                  </Container>
              </Box>
          </Box>
      );
  }
  